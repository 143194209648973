import TextField from "@mui/material/TextField";
import { Button, Title } from "react-admin";

import { useState } from "react";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Box from "@mui/material/Box";
import apiConfig from "config/apiconfig.json";
export const TradeStation = () => {
  const [apiResult, SetAPIResult] = useState("");

  const TradeStationBuy = (props: any) => {
    SetAPIResult("");
    sendTradestationAction();
  };

  const ClearTradierResult = (props: any) => {
    SetAPIResult("");
  };

  const sendTradestationAction = async () => {
    let newData: any;
    let apiSymbol: any = document.getElementById("apiSymbol") as HTMLElement;

    let fullURL = apiConfig.api_url + "/buystock?symbol=" + apiSymbol.value;

    const headers = { Authorization: apiConfig.api_value };
    const response = await fetch(fullURL, { headers });

    newData = await response.json();
    if (newData == null) {
      SetAPIResult("");
    }
    if (response.status !== 200) {
      SetAPIResult("");
      return [false, newData];
    }

    let dataString: any;
    dataString = "";
    dataString += "Order ID:" + newData.OrderID + "\n";
    dataString +=
      "Average Execution Price:" + newData.AverageExecutionPrice + "\n";
    dataString += "Quantity:" + newData.TotalQuantity + "\n";
    dataString += "Order Status:" + newData.OrderStatus + "\n";

    SetAPIResult(dataString);
  };

  return (
    <div>
      <Title title="TradeStation API Calls" />

      <h2>Testing calls to Trade Station</h2>

      <Box
        sx={{
          width: "80%",
          margin: "0px",
          maxWidth: "80%",
          padding: "10px 10px",
        }}
      >
        <TextField required id="apiSymbol" label="Symbol" variant="filled" />
      </Box>
      <Button
        sx={{ margin: "1em" }}
        size="medium"
        variant="contained"
        label="TradeStation BUY"
        onClick={TradeStationBuy}
      >
        <CompareArrowsIcon />
      </Button>

      <Box
        sx={{
          width: "80%",
          margin: "0px",
          maxWidth: "80%",
          padding: "10px 10px",
        }}
      >
        <TextField
          id="apiResult"
          label="API Result"
          value={apiResult}
          multiline
          fullWidth
          rows={10}
          variant="filled"
        />
      </Box>
    </div>
  );
};
