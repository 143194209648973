// in src/App.js
import apiConfig from "config/apiconfig.json";
import * as React from "react";
import {
  Admin,
  CustomRoutes,
  Loading,
  Resource,
  fetchUtils,
} from "react-admin";
import { Route } from "react-router-dom";

import { defaultTheme } from "react-admin";

import appsettings from "./components/forms/appSettings";
import currentstockprices from "./components/forms/currentstockprices";
import localusers from "./components/forms/localusers";
import lookUpTypes from "./components/forms/lookUpTypes";
import permissions from "./components/forms/permissions";
import roles from "./components/forms/roles";
import tradingplatforms from "./components/forms/tradingplatforms";
import weeklystockwatches from "./components/forms/weeklystockwatches";

import { Dashboard } from "./components/forms/dashboard/Dashboard";
import { TradierAPI } from "./components/forms/tradierapi/TradierAPI";
import { TradeStation } from "./components/forms/tradeStation/TradeStation";

import { makeStyles } from "@material-ui/core/styles";
import simpleRestProvider from "ra-data-simple-rest";
import { Layout } from "./components/layout";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak, { KeycloakConfig, KeycloakTokenParsed } from "keycloak-js";
import useAuthProvider from "./components/authProvider/keycloakAuthProvider";
import kcConfig from "./config/keycloakConfig.json";

import { UseStyles } from "components/getForms/FormUtilities";

//const config = new Keycloak(keycloakConfig);
const config: KeycloakConfig = kcConfig;
const keycloak = new Keycloak(config);

//from github.com/react-keycloak/react-keycloak/issues/183
//NOT sure if this checkLoginIframe: false is the best long term solution
const initOptions = { onLoad: "login-required", checkLoginIframe: false };

declare global {
  var InternalBusinessEntityId: string;
  var PreferredUsername: string;
  var DisplayUserName: string;
  var PersonId: number;
  var X_KovestTrading_Token: any;
  var PermissionsSet: boolean;
  var MenuRolesSet: boolean;
  var AppSettingsSet: boolean;
  var UserPermissions: any;
  var UserRoles: any;
  var MenuItems: any;
  var MenuItemRoles: any;

  var AppSettings: { INVENTORYTRANSFER_REQUIRE_ACCEPTANCE: boolean };
}

// var theme
//   process.env.NODE_ENV !== "production"
//     ? unstable_createMuiStrictModeTheme(lightTheme)
//     : createTheme(lightTheme);

const useStyles = makeStyles({
  customSidebar: {
    backgroundColor: "green",
    color: "#ffffff",
    height: "100%",
    boxShadow:
      "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
  },
});

const getPermissions = (decoded: KeycloakTokenParsed): boolean => {
  if (!decoded.resource_access) {
    return false;
  }

  return true;
};

const KeycloakAdmin = () => {
  const customAuthProvider = useAuthProvider(getPermissions);

  if (typeof UserPermissions === "undefined") {
    globalThis.UserPermissions = [];

    localStorage.setItem("UserPermissions", JSON.stringify([]));
  }
  if (typeof UserRoles === "undefined") {
    globalThis.UserRoles = [];

    localStorage.setItem("UserRoles", JSON.stringify([]));
  }

  if (typeof PersonId === "undefined") {
    globalThis.PersonId = 0;
  }
  if (typeof PermissionsSet === "undefined") {
    globalThis.PermissionsSet = false;
  }
  if (typeof AppSettings === "undefined") {
    globalThis.AppSettings = { INVENTORYTRANSFER_REQUIRE_ACCEPTANCE: false };
  }
  if (typeof AppSettingsSet === "undefined") {
    globalThis.AppSettingsSet = false;
  }
  if (typeof PreferredUsername === "undefined") {
    globalThis.PreferredUsername = "";
  }
  if (typeof DisplayUserName === "undefined") {
    globalThis.DisplayUserName = "";
  }

  if (typeof InternalBusinessEntityId === "undefined") {
    globalThis.InternalBusinessEntityId = "";
  }

  globalThis.PreferredUsername = keycloak.tokenParsed?.preferred_username;
  if (keycloak.token !== undefined) {
    globalThis.X_KovestTrading_Token = keycloak.idToken;
  }

  const currentLayout = Layout;
  //const theme = createTheme(defaultTheme);

  const myTheme = {
    ...defaultTheme,

    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
    components: {
      ...defaultTheme.components,
      MuiTextField: {
        defaultProps: {
          variant: "outlined" as const,
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: "outlined" as const,
        },
      },
    },
  };

  // const theme: ThemeOptions = {
  //   shape: {
  //     borderRadius: 10,
  //   },
  // };

  const classes = UseStyles();

  if (false) {
    return (
      <Loading
        className={classes.loading}
        loadingPrimary="Loading..."
        loadingSecondary=""
      />
    );
  }
  //console.log(token)
  // //Check on this?
  // const httpClient = (url: any, options = {}) => {
  //   // @ts-ignore
  //   if (!options.headers) {
  //     // @ts-ignore
  //     options.headers = new Headers({ Accept: "application/json" });
  //   }
  //   // add your own headers here
  //   // @ts-ignore
  //   options.headers.set("api_key", "123abc");
  //   // @ts-ignore
  //   //options.headers.set("Authorization", `${token}`);
  //   //options.headers.set(apiConfig.api_key, apiConfig.api_value);
  //   return fetchUtils.fetchJson(url, options);
  // };

  const httpClient = (url: any, options: fetchUtils.Options = {}) => {
    // note that the type comes from fetchUtils
    //options.credentials = "include";
    const requestHeaders = (options?.headers ||
      new Headers({
        Accept: "application/json",
      })) as Headers;

    requestHeaders.set("Authorization", apiConfig.api_value);

    //requestHeaders.set("X-Custom-Header", "foobar");
    options.headers = requestHeaders;

    //console.log({ options });
    return fetchUtils.fetchJson(url, options);
  };

  //const dataProvider = simpleRestProvider(apiConfig.api_url, httpClient);
  const dataProvider = simpleRestProvider(apiConfig.api_url, httpClient);

  return (
    <Admin
      layout={currentLayout}
      theme={myTheme}
      loginPage={false}
      authProvider={customAuthProvider}
      dashboard={Dashboard}
      title="Kovest Trading App"
      dataProvider={dataProvider}
    >
      <Resource name="lookuptypes" {...lookUpTypes} />
      <Resource name="appsettings" {...appsettings} />
      <Resource name="permissions" {...permissions} />
      <Resource name="quests" {...permissions} />
      <Resource name="roles" {...roles} />
      <Resource name="localusers" {...localusers} />
      <Resource name="usertradingplatformsetup" />
      <Resource name="tradingplatforms" {...tradingplatforms} />
      <Resource name="weeklystockwatches" {...weeklystockwatches} />
      <Resource name="stocktransactions" />
      <Resource name="lastweeklystocktransactions" />
      <Resource name="updatestalledposition" />
      <Resource name="rollbackstalledposition" />

      <Resource name="currentstockprices" {...currentstockprices} />
      <Resource name="parameters" {...permissions} />
      <CustomRoutes>
        <Route path="/tradierapi" element={<TradierAPI />} />
        <Route path="/tradestation" element={<TradeStation />} />
      </CustomRoutes>
    </Admin>
  );
};

const App = () => (
  <ReactKeycloakProvider
    authClient={keycloak}
    LoadingComponent={<div />}
    initOptions={initOptions}
  >
    <React.Fragment>
      <KeycloakAdmin />
    </React.Fragment>
  </ReactKeycloakProvider>
);

export default App;
